import { Card, Carousel, Tooltip } from "@material-tailwind/react";
import { Media, Post } from "../../utils/post";
import { useState } from "react";
import { AiOutlineEye, AiOutlineLike, AiOutlineComment, AiOutlineShareAlt } from "react-icons/ai";

type PostCardProps = {
    post: Post;
    onLike?: (postId: string) => void;
    onShare?: (postId: string) => void;
    onComment?: (postId: string) => void;
}

const PostCard = ({ post, onLike, onShare, onComment }: PostCardProps) => {
    const [isMediaLoading, setIsMediaLoading] = useState(true);

    const handleMediaLoad = () => {
        setIsMediaLoading(false);
    };

    const renderMedia = (medias: Media[]) => {
        if (!medias || medias.length === 0) {
            return <div className="w-full h-full flex justify-center items-center bg-gray-100">No media available</div>;
        }

        if (medias.length === 1) {
            const mediaLink = medias[0].mediaLink;
            const fileExtension = mediaLink.split(".").pop()?.toLowerCase();
            
            const loadingOverlay = isMediaLoading ? (
                <div className="absolute inset-0 flex justify-center items-center bg-gray-100 bg-opacity-50">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-bluePostaam-600"></div>
                </div>
            ) : null;

            if (fileExtension === "mp4" || fileExtension === "avi" || fileExtension === "mov" || fileExtension === "gif") {
                return (
                    <div className="relative w-full h-full">
                        {loadingOverlay}
                        <video 
                            controls 
                            className="w-full h-full object-contain"
                            onLoadedData={handleMediaLoad}
                        >
                            <source src={mediaLink} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                );
            } else if (fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png") {
                return (
                    <div className="relative w-full h-full">
                        {loadingOverlay}
                        <img
                            src={mediaLink}
                            alt={post.postName || "Post Photo"}
                            className="w-full h-full object-contain"
                            onLoad={handleMediaLoad}
                        />
                    </div>
                );
            } else {
                return <div className="w-full h-full flex justify-center items-center">Unsupported Media Type</div>;
            }
        } else {
            return (
                <Carousel 
                    className="w-full h-full"
                    navigation={({ setActiveIndex, activeIndex, length }) => (
                        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                            {new Array(length).fill("").map((_, i) => (
                                <span
                                    key={i}
                                    className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                        activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                    }`}
                                    onClick={() => setActiveIndex(i)}
                                />
                            ))}
                        </div>
                    )}
                >
                    {medias.map((media, index) => (
                        <div
                            key={index}
                            className="h-full w-full flex justify-center items-center relative"
                        >
                            {isMediaLoading && (
                                <div className="absolute inset-0 flex justify-center items-center bg-gray-100 bg-opacity-50">
                                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-bluePostaam-600"></div>
                                </div>
                            )}
                            <img
                                src={media.mediaLink}
                                alt={`${post.postName || "Post"} - Image ${index + 1}`}
                                className="w-full h-full object-contain"
                                onLoad={handleMediaLoad}
                            />
                        </div>
                    ))}
                </Carousel>
            );
        }
    };

    const handleLikeClick = () => {
        if (onLike && post._id) {
            onLike(post._id);
        }
    };

    const handleCommentClick = () => {
        if (onComment && post._id) {
            onComment(post._id);
        }
    };

    const handleShareClick = () => {
        if (onShare && post._id) {
            onShare(post._id);
        }
    };

    return (
        <Card className="rounded-lg bg-white overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
            <div>
                {/* User info section */}
                <div className="p-4 flex items-center space-x-3 border-b border-gray-100">
                    <div className="w-10 h-10 rounded-full bg-bluePostaam-100 flex items-center justify-center text-bluePostaam-600 font-semibold">
                        {post.userName?.charAt(0).toUpperCase() || "U"}
                    </div>
                    <div>
                        <h2 className="font-medium text-gray-800">
                            {post.userName}
                        </h2>
                        <p className="text-xs text-gray-500">
                            {post.createdAt ? new Date(post.createdAt).toLocaleString() : "Recently posted"}
                        </p>
                    </div>
                </div>

                {/* Media section */}
                <div className="h-[30rem] flex justify-center items-center bg-gray-50 relative overflow-hidden">
                    {renderMedia(post.medias)}
                </div>

                {/* Post info section */}
                <div className="p-5 space-y-4">
                    <div className="flex justify-between items-start">
                        <h3 className="text-xl font-medium text-gray-800 capitalize">
                            {post.postName}
                        </h3>
                        <span className="text-sm font-medium text-white rounded-full capitalize bg-bluePostaam-600 px-3 py-1">
                            {post.postType}
                        </span>
                    </div>

                    {/* Engagement stats */}
                    <div className="grid grid-cols-4 gap-2 border rounded-lg p-3 bg-gray-50">
                        <div className="flex flex-col items-center cursor-pointer hover:text-bluePostaam-600 transition-colors duration-200" onClick={() => {}}>
                            <div className="flex items-center justify-center space-x-1">
                                <AiOutlineEye className="text-xl" />
                                <span className="font-medium">{post.views || 0}</span>
                            </div>
                            <p className="text-xs font-medium text-gray-600">Views</p>
                        </div>
                        
                        <div 
                            className="flex flex-col items-center cursor-pointer hover:text-bluePostaam-600 transition-colors duration-200" 
                            onClick={handleLikeClick}
                        >
                            <div className="flex items-center justify-center space-x-1">
                                <AiOutlineLike className="text-xl" />
                                <span className="font-medium">{post.likes || 0}</span>
                            </div>
                            <p className="text-xs font-medium text-gray-600">Likes</p>
                        </div>
                        
                        <div 
                            className="flex flex-col items-center cursor-pointer hover:text-bluePostaam-600 transition-colors duration-200"
                            onClick={handleCommentClick}
                        >
                            <div className="flex items-center justify-center space-x-1">
                                <AiOutlineComment className="text-xl" />
                                <span className="font-medium">{post.comments || 0}</span>
                            </div>
                            <p className="text-xs font-medium text-gray-600">Comments</p>
                        </div>
                        
                        <div 
                            className="flex flex-col items-center cursor-pointer hover:text-bluePostaam-600 transition-colors duration-200"
                            onClick={handleShareClick}
                        >
                            <div className="flex items-center justify-center space-x-1">
                                <AiOutlineShareAlt className="text-xl" />
                                <span className="font-medium">{post.shares || 0}</span>
                            </div>
                            <p className="text-xs font-medium text-gray-600">Shares</p>
                        </div>
                    </div>

                    {/* Tags section */}
                    {post.tags && post.tags.length > 0 && (
                        <div className="w-full">
                            <h4 className="text-base font-medium text-gray-700 pb-2">
                                Tags
                            </h4>
                            <div className="flex flex-wrap w-full gap-2 max-h-24 overflow-y-auto custom-scrollbar">
                                {post.tags.map((tag, index) => (
                                    <Tooltip key={index} content={`Tag: ${tag.tagName}`}>
                                        <span className="bg-bluePostaam-500 px-3 py-1 text-white rounded-full text-sm cursor-pointer hover:bg-bluePostaam-600 transition-colors">
                                            #{tag.tagName}
                                        </span>
                                    </Tooltip>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Tagged users section */}
                    {post.taggedUsers && post.taggedUsers.length > 0 && (
                        <div className="w-full">
                            <h4 className="text-base font-medium text-gray-700 pb-2">
                                Tagged Users
                            </h4>
                            <div className="flex flex-wrap w-full gap-2 max-h-24 overflow-y-auto custom-scrollbar">
                                {post.taggedUsers.map((user, index) => (
                                    <Tooltip key={index} content={`${user.fullname} - ${user.userHandle}`}>
                                        <span className="bg-bluePostaam-400 px-3 py-1 text-white rounded-full text-sm cursor-pointer hover:bg-bluePostaam-500 transition-colors">
                                            {user.fullname} [{user.userHandle}]
                                        </span>
                                    </Tooltip>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default PostCard;