import React, { useState, useEffect } from "react";
import { Button, Spinner } from "@material-tailwind/react";
import { GigDetailsPosts, RootObject } from "../../../utils/gig";
import { Post } from "../../../utils/post";
import PostCard from "../../post/PostCard";
import { IoGridOutline, IoListOutline } from "react-icons/io5";

interface SubmitTabProps {
  id: string;
}

const SubmitTab: React.FC<SubmitTabProps> = ({ id }) => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMorePosts, setHasMorePosts] = useState<boolean>(true);
  const postsPerPage = 30;

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = async (page: number = 1) => {
    setLoading(true);
    try {
      const response: RootObject = await GigDetailsPosts({
        id: id,
        page: page,
        limit: postsPerPage,
        gigStatus: "Submit"
      });

      if (response.status === 200) {
        const newPosts = response.data.posts || [];
        
        // If it's the first page, replace the posts array
        // Otherwise, append to existing posts
        if (page === 1) {
          setPosts(newPosts);
        } else {
          setPosts(prevPosts => [...prevPosts, ...newPosts]);
        }
        
        // Check if there are more posts to load
        setHasMorePosts(newPosts.length >= postsPerPage);
        setCurrentPage(page);
      }
    } catch (error) {
      console.error("Error loading posts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewMoreClick = () => {
    loadPosts(currentPage + 1);
  };

  // Empty state when no posts are available
  if (posts.length === 0 && !loading) {
    return (
      <div className="flex flex-col items-center justify-center py-16">
        <div className="bg-gray-100 p-6 rounded-full mb-4">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-16 w-16 text-gray-400" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={1.5} 
              d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" 
            />
          </svg>
        </div>
        <h3 className="text-xl font-medium text-gray-700 mb-2">No Submissions Yet</h3>
        <p className="text-gray-500 max-w-md text-center">
          There are no submitted posts for this gig yet.
        </p>
      </div>
    );
  }

  return (
    <div className="border-t border-gray-300">
      {/* Header section */}
      <div className="p-4 md:p-8 flex justify-between items-center border-b border-gray-200">
        <h2 className="text-xl font-medium text-gray-700">
          Submitted Content 
          {posts.length > 0 && (
            <span className="text-gray-500 ml-2">({posts.length})</span>
          )}
        </h2>
        
       
      </div>

      {/* Loading state */}
      {loading && posts.length === 0 && (
        <div className="flex justify-center items-center py-20">
          <Spinner className="h-10 w-10 text-bluePostaam-600" />
        </div>
      )}

      {/* Posts grid */}
      <div className="p-4 md:p-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {posts.map((post, index) => (
            <PostCard key={index} post={post} />
          ))}
        </div>

        {/* View more button & loading indicator */}
        {(hasMorePosts || loading) && posts.length > 0 && (
          <div className="mt-8 flex justify-center">
            {loading ? (
              <Spinner className="h-8 w-8 text-bluePostaam-600" />
            ) : (
              <Button 
                className="bg-white border border-bluePostaam-600 text-bluePostaam-600 px-6 py-2 shadow-none hover:shadow-md transition-shadow"
                onClick={handleViewMoreClick}
              >
                View More
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubmitTab;